import { getTheme, pxToRem } from '~/utils';
import { styled } from '~/utils/modules';

// Query
const inMobile = getTheme('inMobile');

// Colors
const secondaryMain = getTheme('secondary.main');
const primaryDark = getTheme('primary.dark');
const secondaryDark = getTheme('secondary.dark');

// Spacing
const smallSpacing = getTheme('smallSpacing');

export const Content = styled.div`
  flex: 1;
  width: 100%;
  background-color: #fafafa;
  flex-direction: column;
`;

export const Childrens = styled.div`
  flex-direction: column;
  padding: ${smallSpacing};
`;

export const Title = styled.h5`
  margin: 0;
  color: ${primaryDark};
  font-size: ${pxToRem(30)};
  margin-bottom: ${smallSpacing};
`;

export const HeaderView = styled.div`
  width: 95%;
  margin: 0 auto;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;

  @media ${inMobile} {
    justify-content: flex-start;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
  }
`;

export const Button = styled.button`
  cursor: pointer;
  border: 0;
  flex-direction: row;
  width: ${pxToRem(200)};
  height: ${pxToRem(50)};
  margin-top: 20px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #fafafa;
  background-color: ${secondaryDark};
  margin-left: ${smallSpacing};

  @media ${inMobile} {
    width: 45%;
    align-items: center;
    height: ${pxToRem(50)};
    font-size: ${pxToRem(15)};
  }
`;
