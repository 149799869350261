import React, { FC } from 'react';
import { Button, Text } from './styles';

type Props = {
  success?: boolean;
  error?: boolean;
  schedule?: boolean;
};

const ButtonStatus: FC<Props> = ({ error, success, schedule }) => (
  <Button error={error} success={success} schedule={schedule}>
    <Text>
      {success
        ? 'Finalizado'
        : error
        ? 'Erro'
        : schedule
        ? 'Agendado'
        : 'Processando'}
    </Text>
  </Button>
);

export default ButtonStatus;
