import React, { FC, useEffect } from 'react';
import { first } from 'lodash';
import { Box, Tab, Tabs } from '@material-ui/core';
import { CardProcess, Header, If } from '~/components';
import { Routing } from '~/routes/routing';
import { observer, useStores } from '~/utils';
import {
  Childrens,
  Content,
  IconStyled,
  SectionCards,
  SectionTitle,
  Title,
} from './process.styles';

const Process: FC = (): JSX.Element => {
  const { user, routing, process } = useStores();

  const goBack = () => routing.back();

  const onSettings = () => {
    routing.push(Routing.SETTINGS);
  };

  const onHome = () => {
    routing.push(Routing.HOME);
  };

  const onSelectSubProcess = (prc: ProcessType.Data) => {
    if (!prc.sub_process.length) return;

    process.setProcess(prc);

    routing.push(Routing.SUBPROCESS, { title: prc.name });
  };

  useEffect(() => {
    process.list();
    process.setClient(process.tab[0]);
  }, []);

  return (
    <Content>
      <Header title="TDM" />

      <Childrens>
        <SectionTitle>
          <div style={{ alignItems: 'center' }}>
            <IconStyled onClick={goBack}>arrow_back</IconStyled>
            <Title>Categoria</Title>
          </div>

          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={process.client[0]}
              onChange={(_, newValue) => process.setClient(newValue)}
            >
              {process.tab.map((client) => (
                <Tab label={client} value={client} />
              ))}
            </Tabs>
          </Box>
        </SectionTitle>

        <SectionCards>
          {process.data
            .filter((e) => e.cliente.includes(process.selectedClient))
            .map(
              ({
                description,
                name,
                repository_name,
                sub_process,
                uid,
                cliente,
                _id,
                external,
              }) => (
                <CardProcess
                  titleBtn="Iniciar"
                  key={uid || _id}
                  onExecute={() =>
                    onSelectSubProcess({
                      _id,
                      description,
                      name,
                      repository_name,
                      sub_process,
                      uid,
                      cliente,
                      external,
                    })
                  }
                  title={name}
                  description={description}
                />
              ),
            )}
        </SectionCards>
      </Childrens>
    </Content>
  );
};

export default observer(Process);
