export const PERMISSIONS = {
  PRD_READY: 'PRD_READY',
};

export const PERMISSIONS_PDM = {
  DEFAULT: [PERMISSIONS.PRD_READY],
};

export const USER_LEVEL = {
  ADMIN: 'admin',
  READER: 'reader',
};

export const USER_LEVEL_LABEL = {
  ADMIN: 'Administrador',
  READER: 'Leitor',
};

export const PERMISSIONS_EXECUTIONS = {
  // VOO
  GERAR_VOO: 'GERAR_VOO',
  VOO_CRIAR_USUARIO: 'VOO_CRIAR_USUARIO',
};

export const PERMISSIONS_EXECUTIONS_LABEL = {
  // VOO
  [PERMISSIONS_EXECUTIONS.GERAR_VOO]: 'Gerar Vôo',
  [PERMISSIONS_EXECUTIONS.VOO_CRIAR_USUARIO]: 'Vôo - Criar Usuário',
};

export const PERMISSIONS_ARRAY = Object.keys(PERMISSIONS_EXECUTIONS);
