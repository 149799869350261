import * as React from 'react';
import '~/index.css';
import { useEffect, useState } from 'react';
import { upperFirst } from 'lodash';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import { colors } from '~/theme';
import {
  STATUS_EXECUTIONS,
  formatDate,
  observer,
  parseProcessName,
  useStores,
} from '~/utils';
import ButtonStatus from '../ButtonStatus';
import { IconStyled } from './styles';

interface HeadCell {
  disablePadding: boolean;
  id: keyof ExecutionsType.TableExecutions;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'date',
    numeric: false,
    disablePadding: true,
    label: 'Data e Hora',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Nome da Massa',
  },
  {
    id: 'emitter_name',
    numeric: true,
    disablePadding: false,
    label: 'Solicitante',
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'uid',
    numeric: true,
    disablePadding: false,
    label: '',
  },
];

const TableExecutions: React.FC = () => {
  const { executions } = useStores();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - executions.table.length)
      : 0;

  const onDownload = (exec: ExecutionsType.ExecutionsResponseAPI) => {
    executions.onDownload(exec);
  };

  useEffect(() => {
    executions.listAll();
  }, [executions]);

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2, flexDirection: 'column' }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size="medium"
            >
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell
                      key={`${headCell.id}-${headCell.label}`}
                      align="left"
                      padding="normal"
                    >
                      <TableSortLabel>{headCell.label}</TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody style={{ margin: 50 }}>
                {executions.table
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={`${row.id}`}
                      sx={{
                        backgroundColor:
                          index % 2 === 0 ? '#f5f5f5' : '#ffffff',
                      }}
                    >
                      <TableCell component="th" align="left">
                        {formatDate(row.dateStart)}
                      </TableCell>
                      <TableCell component="th" align="left">
                        {parseProcessName(row.subcategoria)}
                      </TableCell>
                      <TableCell component="th" align="left">
                        {upperFirst(row.login)}
                      </TableCell>
                      <TableCell component="th" align="left">
                        <ButtonStatus
                          success={row.status === STATUS_EXECUTIONS.SUCCESS}
                          error={row.status === STATUS_EXECUTIONS.ERROR}
                          schedule={row.status === STATUS_EXECUTIONS.SCHEDULE}
                        />
                      </TableCell>
                      <TableCell align="center" width={350}>
                        {row.status === STATUS_EXECUTIONS.SUCCESS ? (
                          <IconStyled
                            style={{
                              color:
                                row.status === STATUS_EXECUTIONS.SUCCESS
                                  ? colors.success
                                  : row.status === STATUS_EXECUTIONS.ERROR
                                  ? colors.failure
                                  : 'transparent',

                              cursor: row.attachment ? 'pointer' : 'default',
                            }}
                            onClick={
                              row.status === STATUS_EXECUTIONS.SUCCESS
                                ? () => onDownload(row)
                                : () => {}
                            }
                          >
                            download
                          </IconStyled>
                        ) : row.status === STATUS_EXECUTIONS.ERROR ? (
                          <div className=".text-column">{row.log_error}</div>
                        ) : null}
                      </TableCell>
                    </TableRow>
                  ))}

                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            component="div"
            count={executions.table.length}
            rowsPerPage={10}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Linhas por página"
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count !== -1 ? count : `mais de ${to}`}`
            }
          />
        </Paper>
      </Box>
    </>
  );
};

export default observer(TableExecutions);
