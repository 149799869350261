export const parseProcessList = (
  response: ProcessType.ListProcessResponse,
): ProcessType.Data => ({
  _id: response._id,
  uid: response.uid,
  name: response.name,
  repository_name: response.repository_name,
  description: response.description,
  cliente: response.cliente,
  sub_process: response.sub_process,
  external: response.external,
});
