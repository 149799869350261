import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from '~/routes/private';
import RouterProvider from '~/routes/provider';
import { EditForm, Home, Process, SubProcess } from '~/scenes';
import { REACT_APP_PRIME_SSO_URL, useStores } from '~/utils';
import { Routing } from './routing';

const SSO_LOGIN_URL = REACT_APP_PRIME_SSO_URL;

const RoutesContainer: FC = () => {
  const { user } = useStores();

  const LoginRedirect = () => {
    // Configurar página de erro se variável não estiver configurada
    if (SSO_LOGIN_URL !== undefined) {
      window.location.href = SSO_LOGIN_URL;
      return null;
    }
    return <div>Aguarde enquanto redirecionamos...</div>; 
  };

  return (
    <Routes>
      <Route path={Routing.HOME} element={<PrivateRoute render={<Home />} />} />
      <Route
        path={Routing.PROCESS}
        element={<PrivateRoute render={<Process />} />}
      />
      <Route
        path={Routing.SUBPROCESS}
        element={<PrivateRoute render={<SubProcess />} />}
      />
      <Route
        path={Routing.EDIT_FORM}
        element={<PrivateRoute render={<EditForm />} />}
      />
      <Route 
        path={Routing.LOGIN} Component={LoginRedirect} />
    </Routes>
  );
};

export { RouterProvider };
export { Routing };
export default RoutesContainer;
