import { getTheme, pxToRem } from '~/utils';
import { styled } from '~/utils/modules';

// Query
const inMobile = getTheme('inMobile');

// Colors
const secondaryDark = getTheme('secondary.dark');
const primaryDark = getTheme('primary.dark');

// Spacing
const smallSpacing = getTheme('smallSpacing');

export const Content = styled.div`
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const Childrens = styled.div`
  flex-direction: column;
  padding: ${smallSpacing};
  margin-bottom: ${smallSpacing};
`;

export const Title = styled.h5`
  margin: 0;
  color: ${primaryDark};
  font-size: ${pxToRem(30)};
  margin: ${smallSpacing} 0px;
`;

export const HeaderView = styled.div`
  width: 100%;
  margin: 0 auto;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  padding-bottom: ${smallSpacing};

  @media ${inMobile} {
    justify-content: flex-start;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
  }
`;

export const Button = styled.button`
  cursor: pointer;
  border: 0;
  flex-direction: row;
  width: ${pxToRem(200)};
  height: ${pxToRem(50)};
  margin-top: 20px;
  font-size: 1.2rem;
  font-weight: 500;
  color: #fafafa;
  background-color: ${secondaryDark};
  margin-left: ${smallSpacing};
  border-radius: 10px;

  @media ${inMobile} {
    width: 45%;
    align-items: center;
    height: ${pxToRem(50)};
    font-size: ${pxToRem(15)};
  }
`;

export const ButtonReload = styled.button`
  cursor: pointer;
  border: 0;
  flex-direction: row;
  width: ${pxToRem(200)};
  height: ${pxToRem(50)};
  margin-top: 20px;
  font-size: 1.2rem;
  font-weight: 500;
  color: ${secondaryDark};
  background-color: #add8e6;
  margin-left: ${smallSpacing};
  border-radius: 10px;

  @media ${inMobile} {
    width: 45%;
    align-items: center;
    height: ${pxToRem(50)};
    font-size: ${pxToRem(15)};
  }
`;
