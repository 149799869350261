import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import { Header } from '~/components';
import { parseProcessName, useStores } from '~/utils';
import {
  Childrens,
  Content,
  DynamicFormStyled,
  FormulationStyled,
  IconStyled,
  SectionTitle,
  Title,
  WrapperContent,
} from './forms.styles';

const EditForm: FC = () => {
  const { process, form, routing } = useStores();

  const {
    state: { title },
  } = useLocation();

  const onAddField = ({
    identifier,
    placeholder,
    type,
    value,
  }: ProcessType.SubProcessFields) => {
    form.createField({
      identifier,
      placeholder,
      type,
      value,
    });
    const selected_sub_process = sessionStorage.getItem('selected_sub_process');
    if (selected_sub_process !== null) {
      const parsed_sub_process = JSON.parse(selected_sub_process);
      process.setOnlySubProcess(parsed_sub_process);
    }
  };

  const onDeleteField = async (identifier: string) => {
    await form.deleteField({ identifier });
    const selected_sub_process = sessionStorage.getItem('selected_sub_process');
    if (selected_sub_process !== null) {
      const parsed_sub_process = JSON.parse(selected_sub_process);
      process.setOnlySubProcess(parsed_sub_process);
    }
  };

  const goBack = () => routing.back();

  useEffect(() => {
    const selected_sub_process = sessionStorage.getItem('selected_sub_process');
    if (selected_sub_process !== null) {
      const parsed_sub_process = JSON.parse(selected_sub_process);
      form.fetch(parsed_sub_process);
    }
  }, []);

  return (
    <Content>
      <Header title="TDM" />
      <Childrens>
        <SectionTitle>
          <IconStyled onClick={goBack}>arrow_back</IconStyled>
          <Title>
            Edição Parâmetros -
            {` ${parseProcessName(title)} > ${parseProcessName(
              process.sub_process.name,
            )}`}
          </Title>
        </SectionTitle>

        <WrapperContent>
          <DynamicFormStyled onChange={onAddField} />

          <FormulationStyled
            editMode
            disableButton
            onChange={() => {}}
            onDeleteField={onDeleteField}
            fields={form.currentForm}
          />
        </WrapperContent>
      </Childrens>
    </Content>
  );
};

export default observer(EditForm);
