import service from '.';

class ExecutionsService {
  static create = async ({
    emitter_id,
    emitter_name,
    name,
    registers = 1,
  }: ExecutionsType.Create) => {
    try {
      const { data } = await service.post('/executions/', {
        emitter_id,
        emitter_name,
        name,
        registers,
      });
      return data;
    } catch (error) {
      // ..
    }
  };

  static list = async ({ emitter_id }: ExecutionsType.ListExecutionsParams) => {
    try {
      const { data } = await service.get(
        `/executions?emitter_id=${emitter_id}`,
      );
      return data;
    } catch (error) {
      // ..
    }
  };
}

export default ExecutionsService;
