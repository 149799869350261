import { Icon } from '@material-ui/core';
import { DynamicForm, Formulation } from '~/components';
import { getTheme, pxToRem } from '~/utils';
import { styled } from '~/utils/modules';

// Query
const inMobile = getTheme('inMobile');

// Colors
const secondaryDark = getTheme('secondary.dark');
const primaryDark = getTheme('primary.dark');

// Spacing
const smallSpacing = getTheme('smallSpacing');
const largeSpacing = getTheme('largeSpacing');

export const Content = styled.div`
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const Childrens = styled.div`
  flex-direction: column;
  padding: ${smallSpacing};
  margin-bottom: ${smallSpacing};
`;

export const Button = styled.button`
  cursor: pointer;
  border: 0;
  flex-direction: row;
  width: ${pxToRem(200)};
  height: ${pxToRem(50)};
  margin-top: 20px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #fafafa;
  background-color: ${secondaryDark};
  margin-left: ${smallSpacing};

  @media ${inMobile} {
    width: 45%;
    align-items: center;
    height: ${pxToRem(50)};
    font-size: ${pxToRem(15)};
  }
`;

export const WrapperContent = styled.div`
  margin-top: ${largeSpacing};
  align-self: center;
  flex-direction: row;

  @media ${inMobile} {
    width: 100%;
    flex-direction: column;
  }
`;

export const DynamicFormStyled = styled(DynamicForm)`
  padding: ${largeSpacing};
  max-height: ${pxToRem(400)};
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);

  @media ${inMobile} {
    width: 100%;
  }
`;

export const FormulationStyled = styled(Formulation)`
  padding-left: ${largeSpacing};

  @media ${inMobile} {
    padding: 0px;
    width: 100%;
    margin-top: ${largeSpacing};
  }
`;

export const SectionTitle = styled.div`
  flex-direction: row;
  align-items: center;
  margin-bottom: ${smallSpacing};
`;

export const Title = styled.h5`
  margin: 0;
  color: ${primaryDark};
  font-size: ${pxToRem(30)};
  margin: ${smallSpacing} 0px;
  margin-left: ${smallSpacing};

  @media ${inMobile} {
    font-size: ${pxToRem(12)};
  }
`;

export const IconStyled = styled(Icon)`
  color: ${primaryDark};
  cursor: pointer;
`;
