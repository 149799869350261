import React, { FC } from 'react';
import { FormikHelpers } from 'formik';
import { Button, FormControl, InputLabel } from '@material-ui/core';
import { Formik, useFormikContext, yup } from '~/utils';
import { Container, Field, Option, SelectStyled } from './form.styles';

const formValidate = yup.object().shape({
  identifier: yup.string().required(),
  placeholder: yup.string().required(),
  type: yup.string().required(),
  value: yup.string(),
});

const initialValues = {
  identifier: '',
  placeholder: '',
  type: 'text',
  value: '',
};

type Props = {
  onChange: (values: Formulation.NewFieldFormsType) => void;
};

const CreateFormComp: React.FC<Props> = ({ onChange, ...rest }) => {
  const submitForm = (
    data: Formulation.ValuesForms,
    { resetForm }: FormikHelpers<any>,
  ) => {
    onChange(data);
    resetForm();
  };

  return (
    <Formik
      onSubmit={submitForm}
      initialValues={initialValues}
      validationSchema={formValidate}
    >
      <CreateForm {...rest} />
    </Formik>
  );
};

const CreateForm: FC<any> = ({ fields, ...rest }) => {
  const { values, handleChange, submitForm, setFieldValue, touched, errors } =
    useFormikContext<Formulation.ValuesForms>();

  const onChangeIdentifier = (value: string) => {
    const parsedValue = value.toLowerCase().replace(/\s/g, '_');
    setFieldValue('identifier', parsedValue);
  };

  return (
    <Container {...rest}>
      <Field
        name="identifier"
        placeholder="Identificador"
        required
        type="text"
        value={values.identifier}
        onChange={(event: any) => onChangeIdentifier(event.target.value)}
        error={!!errors.identifier}
      />

      <Field
        name="placeholder"
        placeholder="Label"
        required
        type="text"
        value={values.placeholder}
        onChange={handleChange('placeholder')}
        error={!!errors.placeholder}
      />

      <FormControl fullWidth style={{ width: 200 }}>
        <InputLabel>Tipo</InputLabel>

        <SelectStyled
          label="Tipo"
          placeholder="Tipo"
          onChange={(e: any) => setFieldValue('type', e.target.value)}
          value={values.type}
        >
          <Option key="number" value="number">
            Numero
          </Option>

          <Option key="text" value="text">
            Texto
          </Option>
        </SelectStyled>
      </FormControl>

      <Field
        name="value"
        placeholder="Valor Padrão"
        required
        type={values.type}
        value={values.value}
        onChange={handleChange('value')}
        error={!!errors.value}
      />

      <Button onClick={submitForm}>Adicionar</Button>
    </Container>
  );
};

export default CreateFormComp;
