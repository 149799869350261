import { getTheme, ifStyle, pxToRem, styled } from '~/utils';

type Props = {
  success?: boolean;
  error?: boolean;
  schedule?: boolean;
};

// Spacing
const smallSpacing = getTheme('smallSpacing');

// Radius
const largeRadius = getTheme('largeRadius');

// colors
const success = getTheme('success');
const failure = getTheme('failure');
const warning = getTheme('warning');
const info = getTheme('info');

// Ifs
const isError = ifStyle('error');
const isSuccess = ifStyle('success');
const isSchedule = ifStyle('schedule');

export const Button = styled.button<Props>`
  border: 0;
  border-radius: ${largeRadius};
  min-width: ${pxToRem(100)};
  height: ${pxToRem(35)};
  background-color: ${isSuccess(
    success,
    isError(failure, isSchedule(warning, info)),
  )}67;
  color: #333;
`;

export const Text = styled.h5`
  margin: 0px;
  font-size: ${pxToRem(13)};
  font-weight: 500;
  padding: 0px ${smallSpacing};
`;
