import React, { FC, useState } from 'react';
import { Header, ModalAddUser, ModalEditUser, TableUser } from '~/components';
import { Routing } from '~/routes/routing';
import { observer, useStores } from '~/utils';
import {
  Button,
  Childrens,
  Content,
  HeaderView,
  Title,
} from './settings.styles';

const Settings: FC = (): JSX.Element => {
  const { user, routing } = useStores();
  const [modalCreateUser, setModalCreateUser] = useState(false);
  const [modalEditUser, setModalEditUser] = useState(false);

  const onModalCreateUser = () => setModalCreateUser(!modalCreateUser);

  const onModalEditUser = () => setModalEditUser(!modalEditUser);

  return (
    <Content>
      <ModalEditUser onCloseModal={onModalEditUser} open={modalEditUser} />
      <ModalAddUser onCloseModal={onModalCreateUser} open={modalCreateUser} />
      <Header title="TDM" />

      <HeaderView>
        <Button onClick={onModalCreateUser}>Criar usuário</Button>
      </HeaderView>

      <Childrens>
        <Title>Configuração</Title>
        <TableUser users={[]} onModalEditUser={onModalEditUser} />
      </Childrens>
    </Content>
  );
};

export default observer(Settings);
