import { create } from 'mobx-persist';
import { RouterStore } from 'mobx-react-router';
import ExecutionsStore from './executions.store';
import FormStore from './form.store';
import ProcessStore from './process.store';
import UserStore from './user.store';

class RootStore {
  user: UserStore;

  form: FormStore;

  process: ProcessStore;

  executions: ExecutionsStore;

  constructor() {
    this.user = new UserStore(this);
    this.process = new ProcessStore(this);
    this.form = new FormStore();
    this.executions = new ExecutionsStore(this);
  }
}

const store = new RootStore();

const hydrate = create({
  storage: localStorage,
});

hydrate('user', store.user);
hydrate('process', store.process);
hydrate('executions', store.executions);

export { RouterStore, UserStore, ProcessStore, FormStore, ExecutionsStore };
export default store;
