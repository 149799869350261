import React, { FC, useState } from 'react';
import { isEmpty, upperFirst } from 'lodash';
import {
  Button,
  // CircularProgress,
  // FormControl,
  // InputLabel,
} from '@material-ui/core';
import { Formik, observer, useFormikContext, useStores } from '~/utils';
import If from '../If';
import {
  Container,
  Field,
  IconRemove,
  TitleField,
  // Option,
  // SelectStyled,
  WrapperField,
  WrpField,
} from './formulation.styles';

const ContainerComp: React.FC<Formulation.Props> = ({
  onChange,
  disableButton = false,
  onDeleteField = () => {},
  ...rest
}) => {
  const { form } = useStores();

  const initialValues = form.currentForm.fields?.reduce(
    (acc, field) => ({
      ...acc,
      [field.identifier]: field.value,
    }),
    {},
  );

  return (
    <Formik
      enableReinitialize
      onSubmit={onChange}
      initialValues={initialValues as any}
    >
      <Formulation
        fields={form.currentForm}
        disableButton={disableButton}
        onDeleteField={onDeleteField}
        {...rest}
      />
    </Formik>
  );
};

const Formulation: FC<Formulation.PropsView> = ({
  fields,
  disableButton,
  onDeleteField,
  editMode = false,
  ...rest
}) => {
  const [loading, setLoading] = useState(false);
  const { handleChange, submitForm, setFieldValue, values } =
    useFormikContext<any>();

  return (
    <Container {...rest}>
      {fields?.fields?.map((field) => (
        <div key={field.identifier}>
          <If condition={field.type !== 'select'}>
            <WrpField>
              <TitleField>{field.identifier}</TitleField>
              <WrapperField>
                <Field
                  key={field.identifier}
                  name={field.identifier}
                  placeholder={upperFirst(field.placeholder)}
                  type={field.type}
                  onChange={handleChange(field.identifier) as any}
                  value={!isEmpty(values) ? values[field.identifier] : ''}
                />

                <If condition={editMode}>
                  <IconRemove onClick={() => onDeleteField(field.identifier)}>
                    delete
                  </IconRemove>
                </If>
              </WrapperField>
            </WrpField>
          </If>

          {/* <If condition={field.type === 'select' && !!field.options}>
            <FormControl fullWidth style={{ width: 200 }}>
              <InputLabel>{field.placeholder}</InputLabel>

              <SelectStyled
                label={field.placeholder}
                placeholder={field.placeholder}
                onChange={(e) =>
                  setFieldValue(field.identifier, e.target.value)
                }
              >
                {field.options?.map((option) => (
                  <Option key={option} value={option || ''}>
                    {option}
                  </Option>
                ))}
              </SelectStyled>
            </FormControl>
          </If> */}
        </div>
      ))}

      <If condition={!disableButton}>
        <Button onClick={submitForm}>Enviar</Button>
      </If>
    </Container>
  );
};

export default observer(ContainerComp);
