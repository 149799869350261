import qs from 'querystring';
import { Request } from '~/services';
import { ResponseError, parseFetchUser } from '~/utils';

export const oAuth = async ({
  password,
  username,
}: UserType.LoginParams): Promise<UserType.LoginResponse> => {
  try {
    const { data } = await Request.post<UserType.LoginResponse>(
      '/login',
      qs.stringify({
        password,
        username,
      }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Access-Control-Allow-Origin': '*',
        },
      },
    );
    return data;
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const fetch = async ({
  username,
}: UserType.FetchUser): Promise<UserType.Data> => {
  try {
    const { data } = await Request.get<UserType.ResponseFetchUser>(
      `/usuario/info`,
    );

    return parseFetchUser(data);
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const fetchAll = async (): Promise<UserType.Data[]> => {
  try {
    const { data } = await Request.get<UserType.ResponseFetchUser[]>(
      `/usuarios`,
    );
    return data.map(parseFetchUser);
  } catch (error) {
    throw new ResponseError(error);
  }
};
