import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { persist } from 'mobx-persist';
import { ProcessAPI } from '~/services/api';
import RootStore from './index';

export default class ProcessStore {
  @observable
  root: typeof RootStore;

  @persist('object')
  @observable
  processSelected: Partial<ProcessType.Data> = {};

  @persist('object')
  @observable
  sub_process: Partial<ProcessType.SubProcessType> = {};

  @observable
  processInView: ProcessType.FirestoreData[] = [];

  @persist('object')
  @observable
  data: ProcessType.Data[] | [] = [];

  @persist('object')
  @observable
  client: string[] = [];

  @persist('object')
  @observable
  selectedClient = '';

  @observable
  tabs: ProcessType.FirestoreData[] = [];

  constructor(root: typeof RootStore) {
    this.root = root;
    makeObservable(this);
  }

  @action
  setClient = (client: string) => {
    this.selectedClient = client;
  };

  @action
  list = async (): Promise<void> => {
    try {
      const data = await ProcessAPI.list();

      runInAction(() => {
        this.data = data;
        // .map((process) => {
        //   // const permissionsUser = this.root.user.data.permissions;

        //   const allowed = permissionsUser.some(
        //     ({ uid_categoria }) => uid_categoria === process.uid,
        //   );

        //   if (allowed) {
        //     return {
        //       ...process,
        //       subProcess: process.subProcess.filter((sub) =>
        //         permissionsUser.some(
        //           ({ uid_subcategoria }) => uid_subcategoria === sub.uid,
        //         ),
        //       ),
        //     };
        //   }

        //   return process;
        // })
        // .filter((process) =>
        //   this.root.user.data.permissions.some(
        //     ({ uid_categoria }) => uid_categoria === process.uid,
        //   ),
        // );
      });
    } catch (error) {
      // ..
    }
  };

  @computed
  get tab() {
    //  return only name client in an array and remove duplicates
    // Extrair os clientes da data
    const clients = this.data.map(({ cliente }) => cliente).flat();

    // Use a Set to remove duplicates
    const uniqueClients = [...new Set(clients)];

    return uniqueClients;
  }

  @computed
  get enumsPermissions() {
    let aIts: any = [];

    const all = this.data.map(({ name, cliente, uid, sub_process }) => {
      let process: any = [];

      sub_process.map(({ name: processName, uid: processId }) => {
        process = [
          ...process,
          { name: `${cliente} | ${name} - ${processName}`, uid, processId },
        ];
      });

      return process;
    });

    all.map((it) => {
      it.map((args: any) => {
        aIts = [...aIts, args];
      });

      return process;
    });

    return aIts;
  }

  @action
  setProcess = (prc: ProcessType.FirestoreData) => {
    this.processSelected = prc;
  };

  @action
  setOnlySubProcess = (pcs: ProcessType.SubProcessType) => {
    this.sub_process = pcs;
  };
}
