import { Icon } from '@material-ui/core';
import { pxToRem } from '~/utils';
import { styled } from '~/utils/modules';

export const Content = styled.div`
  width: 100%;
  height: ${pxToRem(300)};
`;

export const DataGridStyled = styled.div``;

export const SettingsIcon = styled(Icon)`
  margin: 5px;
  cursor: pointer;
  align-self: center;
`;
