import { first } from 'lodash';
import moment from 'moment-timezone';

export const parseIDCollection = (value: string): string => {
  const noAccents = value?.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  const noSpecialChar = noAccents?.replaceAll(/\W/gi, ' ');
  const noMultipleSpaces = noSpecialChar?.replaceAll(/\s+/g, '_');
  const parsedText = noMultipleSpaces?.toUpperCase().trim();
  return parsedText.toUpperCase();
};

export const parseProcessName = (value = '') =>
  String(value).charAt(0).toUpperCase() +
  String(value).slice(1).replaceAll('_', ' ');

export const formatDate = (date = ''): string => {
  if (!date) return ' --- ';

  const parse = first(date.split('.')) as string;

  return moment
    .tz(`${parse}.0Z`, 'America/Sao_Paulo')
    .format('DD/MM/YYYY - HH:mm');
};
