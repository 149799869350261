import React, { FC, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CardProcess, Header, If, ModalExecute } from '~/components';
import { Routing } from '~/routes/routing';
import { observer, parseProcessName, useStores } from '~/utils';
import {
  Childrens,
  Content,
  IconStyled,
  SectionCards,
  SectionTitle,
  Title,
} from './subProcess.styles';

const SubProcess: FC = (): JSX.Element => {
  const [modal, setModal] = useState(false);
  const { user, routing, process, form } = useStores();

  const {
    state: { title },
  } = useLocation();

  const handleModalExecute = () => setModal(!modal);

  const onExecute = async (pcs: ProcessType.SubProcessType) => {
    process.setOnlySubProcess(pcs);
    await form.fetch({ uid: pcs.uid, fields: pcs.fields });
    handleModalExecute();
  };

  const onEditProcess = (sub_process: ProcessType.SubProcessType) => {
    process.setOnlySubProcess(sub_process);
    sessionStorage.setItem('selected_sub_process', JSON.stringify(sub_process));
    routing.push(Routing.EDIT_FORM, { title });
  };

  const goBack = () => routing.back();
  const onHome = () => routing.push(Routing.HOME);
  const onSettings = () => routing.push(Routing.SETTINGS);

  return (
    <Content>
      <ModalExecute open={modal} onCloseModal={handleModalExecute} />
      <Header title="TDM" />
      <Childrens>
        <SectionTitle>
          <IconStyled onClick={goBack}>arrow_back</IconStyled>
          <Title>Sub-Categoria | {parseProcessName(title)}</Title>
        </SectionTitle>

        <SectionCards>
          {process.processSelected?.sub_process?.map((prc) => (
            <CardProcess
              key={prc.uid}
              editMode
              // editMode={user.data.level === USER_LEVEL.ADMIN}
              onExecute={() => onExecute(prc)}
              title={prc.name}
              description={prc.description}
              onEditPress={() => onEditProcess(prc)}
            />
          ))}
        </SectionCards>
      </Childrens>
    </Content>
  );
};

export default observer(SubProcess);
