import * as React from 'react';
import { useState } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import { USER_LEVEL, USER_LEVEL_LABEL, observer, useStores } from '~/utils';
import Dialog from '../Dialog';
import { SettingsIcon } from './styles';

type Props = {
  users: UserType.DataFirestore[];
  onModalEditUser: () => void;
};

interface HeadCell {
  disablePadding: boolean;
  id: keyof UserType.DataFirestore;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'email',
    numeric: false,
    disablePadding: true,
    label: 'Email',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Nome',
  },
  {
    id: 'level',
    numeric: true,
    disablePadding: false,
    label: 'Nível',
  },
  {
    id: 'ts',
    numeric: true,
    disablePadding: false,
    label: '',
  },
];

const TableUsers: React.FC<Props> = ({ users, onModalEditUser }) => {
  const { user } = useStores();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [modalDeleteUser, setModalDeleteUser] = useState(false);
  const [emailQuery, setEmailQuery] = useState('');

  const handleModalDeleteUser = () => setModalDeleteUser(!modalDeleteUser);

  const onDelete = async () => {
    user.remove({ emailQuery });
    handleModalDeleteUser();
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onEdit = async (data: UserType.DataFirestore) => {
    user.setDataUserToEdit(data);
    onModalEditUser();
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2, flexDirection: 'column' }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size="medium"
            >
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell key={headCell.id} align="left" padding="normal">
                      <TableSortLabel>{headCell.label}</TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody style={{ margin: 10 }}>
                {users
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow hover tabIndex={-1} key={row.email}>
                      <TableCell align="left">{row.email}</TableCell>
                      <TableCell align="left">{row.name}</TableCell>
                      <TableCell align="left">
                        {USER_LEVEL.ADMIN === row.level
                          ? USER_LEVEL_LABEL.ADMIN
                          : USER_LEVEL_LABEL.READER}
                      </TableCell>
                      <TableCell align="left">
                        <SettingsIcon
                          style={{ color: '#2D9CDB' }}
                          onClick={() => onEdit(row)}
                        >
                          tune
                        </SettingsIcon>
                        <SettingsIcon
                          onClick={() => {
                            setEmailQuery(row.email);
                            handleModalDeleteUser();
                          }}
                          style={{ marginLeft: 15, color: '#E56657' }}
                        >
                          delete
                        </SettingsIcon>
                      </TableCell>
                    </TableRow>
                  ))}

                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            component="div"
            count={users.length}
            rowsPerPage={5}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Linhas por página"
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count !== -1 ? count : `mais de ${to}`}`
            }
          />
        </Paper>
      </Box>
      <Dialog
        open={modalDeleteUser}
        onCancel={handleModalDeleteUser}
        onSuccess={onDelete}
        title="Excluir usuário"
        description="Deseja realmente continuar com a exclusão deste usuário?"
      />
    </>
  );
};

export default observer(TableUsers);
