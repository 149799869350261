const {
  REACT_APP_ENV,
  REACT_APP_API_URL,
  REACT_APP_X_TOKEN,
  REACT_APP_COGNITO_POOL_CLIENT_ID,
  REACT_APP_COGNITO_POOL_ID,
  REACT_APP_PRIME_SSO_URL,
} = process.env;

export {
  REACT_APP_ENV,
  REACT_APP_API_URL,
  REACT_APP_X_TOKEN,
  REACT_APP_COGNITO_POOL_CLIENT_ID,
  REACT_APP_COGNITO_POOL_ID,
  REACT_APP_PRIME_SSO_URL,
};
