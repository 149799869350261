import { first } from 'lodash';

export const parseFetchUser = (
  data: UserType.ResponseFetchUser,
): UserType.Data => ({
  uid: data.sub,
  name: data.name,
  username: data.preferred_username,
  level: first(data.level) || '',
  // permissions: data.permission,
  group: data.group || [],
  enabled: true,
});
