import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { CircularProgress } from '@material-ui/core';
import { Header, If, TableExecutions } from '~/components';
import { Routing } from '~/routes';
import { useStores } from '~/utils';
import {
  Button,
  ButtonReload,
  Childrens,
  Content,
  HeaderView,
  Title,
} from './home.styles';

const Home: FC = () => {
  const { user, routing, process, executions } = useStores();
  const [loading, setLoading] = useState(false);

  const onNewExecution = () => routing.push(Routing.PROCESS);

  const initialization = () => {
    process.list();
  };

  const onReload = () => {
    setLoading(true);
    process.list();
    user.fetchUser();
    executions.listAll();
    setLoading(false);
  };

  useEffect(() => {
    initialization();
  }, []);

  return (
    <Content>
      <Header title="TDM" />

      <Childrens>
        <HeaderView>
          <Title>Execuções</Title>

          <div>
            <If condition={!loading}>
              <ButtonReload onClick={onReload}>Atualizar</ButtonReload>
            </If>

            <If condition={loading}>
              <ButtonReload onClick={() => {}}>
                <CircularProgress size={20} style={{ color: '#333' }} />
              </ButtonReload>
            </If>

            <If condition={process.data.length > 0}>
              <Button onClick={onNewExecution}>Nova Massa</Button>
            </If>
          </div>
        </HeaderView>

        <TableExecutions />
      </Childrens>
    </Content>
  );
};

export default observer(Home);
