import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { createBrowserHistory } from 'history';
import { Provider } from 'mobx-react';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import { BrowserRouter, Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { CssBaseline } from '@material-ui/core';
import Routes, { RouterProvider } from '~/routes';
import store from '~/stores';
import { breakpoints, colors, radius, spacings } from '~/theme';
import { ToastContainer } from './utils/modules';

const App = () => {
  const browserHistory = createBrowserHistory();
  const routingStore = new RouterStore();
  const routing = syncHistoryWithStore(browserHistory, routingStore);

  const stores = { ...store, routing };

  const theme = {
    ...colors,
    ...spacings,
    ...radius,
    ...breakpoints,
  };

  return (
    <Provider {...stores} rootStore={{ ...store, routing }}>
      <ToastContainer position="top-left" />
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <RouterProvider routing={routing}>
          <Routes />
        </RouterProvider>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
