import React, { FC, useEffect, useState } from 'react';
import { Checkbox, CircularProgress } from '@material-ui/core';
import { Routing } from '~/routes';
import {
  Formik,
  observer,
  parseProcessName,
  useFormikContext,
  useStores,
} from '~/utils';
import If from '../If';
import {
  Button,
  Content,
  Field,
  IconStyled,
  ModalStyled,
  SectionForms,
  SectionTitle,
  Title,
  TitleField,
  WrpField,
} from './styles';

type Props = {
  open?: boolean;
  onCloseModal?: () => void;
};

const ModalExecute: FC<Props> = ({ open, onCloseModal = () => {} }) => {
  const [fields, setFields] = useState<Record<string, any>>({});
  const [initialValues, setInitialValues] = useState<Record<string, any>>({});
  const { executions, routing, form } = useStores();

  const onSubmit = async (
    { quantity, ...rest }: Record<string, any>,
    { resetForm }: any,
  ) => {
    executions.create({ quantity, ...rest });
    executions.listAll();
    resetForm();
    onCloseModal();
    routing.push(Routing.HOME);
  };

  useEffect(() => {
    const initialValues: Record<string, any> = {};
    form.currentForm.fields?.forEach((field) => {
      initialValues[field.identifier] = field.value || ''; // Defina o valor inicial aqui
    });
    setInitialValues(initialValues);
  }, [form.currentForm]);

  return (
    <Formik
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      <Children onCloseModal={onCloseModal} open={open} />
    </Formik>
  );
};

const Children: FC<Props> = ({ onCloseModal = () => {}, open = false }) => {
  const { form, process } = useStores();
  const [initialValues, setInitialValues] = useState<Record<string, any>>({});
  const { handleChange, submitForm, isSubmitting, values } =
    useFormikContext<any>();

  return (
    <ModalStyled open={open}>
      <Content>
        <SectionTitle>
          <Title>{parseProcessName(process.sub_process.name)}</Title>
          <IconStyled
            onClick={() => {
              onCloseModal();
              form.currentForm = {};
            }}
          >
            close
          </IconStyled>
        </SectionTitle>

        <SectionForms>
          {form.currentForm.fields?.map(
            ({ identifier, placeholder, type, value: value_field }) => (
              <WrpField key={identifier}>
                <TitleField>{placeholder}</TitleField>
                <Field
                  placeholder={placeholder}
                  name={identifier}
                  type={type}
                  value={values[identifier]}
                  onChange={handleChange(identifier)}
                />
              </WrpField>
            ),
          )}
        </SectionForms>

        <Button onClick={submitForm}>
          <If condition={!isSubmitting}>Iniciar</If>
          <If condition={isSubmitting}>
            <CircularProgress size={20} style={{ color: 'white' }} />
          </If>
        </Button>
      </Content>
    </ModalStyled>
  );
};
export default observer(ModalExecute);
