import { Icon } from '@material-ui/core';
import { pxToRem } from '~/utils';
import { styled } from '~/utils/modules';

type Props = {
  finishExecution?: boolean;
};

export const Content = styled.div`
  width: 100%;
  background-color: #fafafa;
  height: ${pxToRem(300)};
`;

export const DataGridStyled = styled.div``;

export const IconStyled = styled(Icon)<Props>`
  margin: 5px;
  cursor: pointer;
  align-self: center;
`;
