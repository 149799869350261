import React, { FC, useEffect, useState } from 'react';
import { first, isEmpty } from 'lodash';
import {
  Checkbox,
  CircularProgress,
  ListItemText,
  Modal,
} from '@material-ui/core';
import {
  Formik,
  USER_LEVEL,
  USER_LEVEL_LABEL,
  useFormikContext,
  useStores,
} from '~/utils';
import If from '../If';
import {
  Button,
  Content,
  Field,
  IconStyled,
  LevelUserText,
  Option,
  SelectStyled,
  TextError,
} from './styles';

type Props = {
  open?: boolean;
  onCloseModal?: () => void;
};

type ChildrenProps = {
  open?: boolean;
  onCloseModal?: () => void;
  data: Partial<UserType.DataFirestore>;
  optionsPermissions?: ProcessType.OptionsPermissions[];
};

const ModalEditUser: FC<Props> = ({ open, onCloseModal = () => {} }) => {
  const { user, process } = useStores();

  const onSubmit = async (data: any) => {
    await user.update({ ...data, emailQuery: data.email });
    onCloseModal();
  };

  useEffect(() => {
    process.list();
  }, []);

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        email: '',
        name: '',
        level: '',
        permissions: [''],
        ...user.userEditing!,
      }}
      enableReinitialize
    >
      <Children
        onCloseModal={onCloseModal}
        open={open}
        data={user.userEditing}
        optionsPermissions={process.enumsPermissions}
      />
    </Formik>
  );
};

const Children: FC<ChildrenProps> = ({
  onCloseModal = () => {},
  open = false,
  data,
  optionsPermissions,
}) => {
  const [selected, setSelected] = useState<string[]>([]);
  const {
    values,
    errors,
    handleChange,
    setFieldValue,
    submitForm,
    isSubmitting,
    resetForm,
  } = useFormikContext<FirebaseType.EditUserParams>();

  const handlePermissions = (event: Record<string, any>) => {
    const value =
      typeof event.target.value === 'string'
        ? event.target.value.split(',')
        : event.target.value;

    setSelected(value);

    setFieldValue('permissions', value);
  };

  const onClose = () => {
    onCloseModal();
    resetForm();
    setSelected([]);
  };

  // useEffect(() => {
  //   setSelected(data.permissions || []);
  // }, [data]);

  return (
    <Modal open={open}>
      <Content>
        <IconStyled onClick={onClose}>close</IconStyled>
        <Field
          placeholder="Nome Completo"
          name="name"
          type="text"
          onChange={handleChange('name')}
          value={values.name}
          error={!!errors.name}
        />

        <Field
          placeholder="Email"
          name="email"
          type="email"
          value={values.email}
          disabled
        />

        <LevelUserText>Nível do usuário</LevelUserText>
        <SelectStyled
          onChange={(e: any) => setFieldValue('level', e.target.value)}
          value={values.level}
        >
          {[USER_LEVEL.ADMIN, USER_LEVEL.READER].map((option) => (
            <Option key={option} value={option || ''}>
              {option === USER_LEVEL.ADMIN
                ? USER_LEVEL_LABEL.ADMIN
                : USER_LEVEL_LABEL.READER}
            </Option>
          ))}
        </SelectStyled>

        <LevelUserText>Permissões</LevelUserText>
        <SelectStyled
          multiple
          value={selected}
          onChange={(e: Record<string, any>) => handlePermissions(e)}
          renderValue={(selected: any = []) => {
            const option = selected.map((uid: string) => {
              const pp = first(
                optionsPermissions?.filter(
                  ({ processId }) => processId === uid,
                ),
              ) as any;
              return `${pp.name}, `;
            });

            return option;
          }}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 48 * 4.5 + 8,
                width: 250,
              },
            },
          }}
        >
          {optionsPermissions?.map(({ name, processId }) => (
            <Option key={processId} value={processId}>
              <Checkbox checked={selected.indexOf(processId) > -1} />
              <ListItemText primary={name} />
            </Option>
          ))}
        </SelectStyled>

        <TextError>
          {errors.name || errors.email || errors.level || ' '}
        </TextError>

        <Button onClick={submitForm} disabled={isEmpty(values.email)}>
          <If condition={!isSubmitting}> Atualizar</If>
          <If condition={isSubmitting}>
            <CircularProgress size={20} style={{ color: 'white' }} />
          </If>
        </Button>
      </Content>
    </Modal>
  );
};
export default ModalEditUser;
