import { MenuItem, Select } from '@material-ui/core';
import { getTheme, ifStyle, pxToRem } from '~/utils';
import { styled } from '~/utils/modules';

type Props = {
  error?: boolean;
};

// Ifs
const isError = ifStyle('error');
const inMobile = getTheme('inMobile');

// Colors
const failure = getTheme('failure');

export const Container = styled.div`
  flex-direction: column;
`;

export const Field = styled.input<Props>`
  width: ${pxToRem(300)};
  background-color: #e4dede;
  border: 2px solid ${isError(failure, '#fff')};
  border-radius: ${pxToRem(2.5)};
  height: ${pxToRem(55)};
  font-size: ${pxToRem(20)};
  padding: ${pxToRem(10)};
  margin-bottom: ${pxToRem(15)};

  @media ${inMobile} {
    width: 100%;
  }
`;

export const SelectStyled = styled(Select)`
  width: ${pxToRem(300)};
  border: 1px solid #cbbeb5;
  margin-bottom: ${pxToRem(15)};

  @media ${inMobile} {
    width: 100%;
  }
`;

export const Option = styled(MenuItem)`
  width: 100%;
`;
