/* eslint-disable no-console */
import axios from 'axios';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { persist } from 'mobx-persist';
import { ExecutionsAPI } from '~/services/api';
import RootStore from './index';

export default class FormStore {
  @observable
  root: typeof RootStore;

  @persist('object')
  @observable
  table: ExecutionsType.ExecutionsResponseAPI[] | [] = [];

  constructor(root: typeof RootStore) {
    this.root = root;
    makeObservable(this);
  }

  @action
  create = async ({ quantity = 1, ...fields }: ExecutionsType.CreateParams) => {
    try {
      if (this.root.process.processSelected.external) {
        await ExecutionsAPI.schedule({
          repository_name: this.root.process.processSelected.repository_name,
          quantity,
          processUid: this.root.process.processSelected.name,
          subProcessUid: this.root.process.sub_process.name,
          custom_fields: fields,
          group: this.root.process.client,
        });
      } else {
        await ExecutionsAPI.run({
          repository_name: this.root.process.processSelected.repository_name,
          quantity,
          processUid: this.root.process.processSelected.name,
          subProcessUid: this.root.process.sub_process.name,
          custom_fields: fields,
          group: this.root.process.processSelected.cliente,
        });
      }

      this.listAll();
    } catch (error) {
      // ..
    }
  };

  @action
  listAll = async (): Promise<void> => {
    try {
      this.table = [];

      const data = await ExecutionsAPI.listAll();

      runInAction(() => {
        this.table = data.reverse();
      });
    } catch (error) {
      // ..
    }
  };

  @action
  onDownload = async (exec: ExecutionsType.ExecutionsResponseAPI) => {
    const { subcategoria, id } = exec;

    try {
      await ExecutionsAPI.download(id, subcategoria);
    } catch (error) {
      // ..
    }
  };
}
