import { Icon, MenuItem, Modal, Select } from '@material-ui/core';
import { getTheme, ifStyle, pxToRem } from '~/utils';
import { styled } from '~/utils/modules';

type FieldType = {
  error?: boolean;
};

// Query
const inMobile = getTheme('inMobile');

// Colors
const primaryMain = getTheme('primary.main');
const failure = getTheme('failure');

// Radius
const mediumRadius = getTheme('mediumRadius');

// Spacing
const mediumSpacing = getTheme('mediumSpacing');

// Ifs
const isError = ifStyle('error');
const isDisabled = ifStyle('disabled');

export const Content = styled.div`
  flex-direction: column;
  width: 30%;
  min-height: 20%;
  margin: 0 auto;
  align-self: center;
  border-radius: ${mediumRadius};
  padding: ${mediumSpacing};
  background-color: ${primaryMain};
  align-items: center;
  justify-content: space-between;

  @media ${inMobile} {
    width: 90%;
  }
`;

export const Field = styled.input<FieldType>`
  border: ${(props) => isError(`2px solid ${failure(props)}`, 0)(props)};
  border-radius: 10px;
  height: ${pxToRem(55)};
  background-color: rgba(0, 36, 76, 0.2);
  padding: 10px;
  font-size: 1rem;
  color: #333;
  width: 100%;

  @media ${inMobile} {
    border-radius: 5px;
    height: ${pxToRem(40)};
    width: 100%;
  }
`;

export const Button = styled.button`
  cursor: pointer;
  border: 0;
  border-radius: 10px;
  height: ${pxToRem(50)};
  background-color: ${isDisabled('rgba(0, 36, 76, 0.5)', 'rgba(0, 36, 76, 1)')};
  margin-top: 20px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #fafafa;
  width: 100%;

  @media ${inMobile} {
    border-radius: 5px;
    width: 100%;
    align-items: center;
    height: ${pxToRem(43)};
  }
`;

export const IconStyled = styled(Icon)`
  width: 90%;
  align-self: flex-end;
  cursor: pointer;
`;

export const ModalStyled = styled(Modal)`
  font-size: ${pxToRem(20)};
`;

export const SectionTitle = styled.div`
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.h5`
  margin: 0px;
`;

export const SectionForms = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow: scroll;
  overflow-y: auto;
  max-height: ${pxToRem(500)};
`;

export const WrpField = styled.div`
  width: 48%;
  margin: 2px;
  display: flex;
  flex-direction: column;
`;

export const TitleField = styled.h6`
  margin: 0;
  font-weight: 500;
  color: #333;
`;
