import React, { FC, useEffect, useState } from 'react';
import { Amplify } from '~/services';
import { useStores } from '~/utils';
import { Routing } from './routing';

type Props = {
  render: React.ReactElement;
};

const amplify = new Amplify();

const PrivateRoute: FC<Props> = ({ render }) => {
  const { routing } = useStores();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const verifyAuthSession = async () => {
      const verify = await amplify.verifyUser();
      if (verify) {
        setIsLoading(false);
      } else {
        routing.replace(Routing.LOGIN);
      }
    };
    verifyAuthSession();
  }, []);

  return isLoading ? <></> : render;
};

export default PrivateRoute;
