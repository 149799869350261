import React, { FC } from 'react';
import { parseProcessName } from '~/utils';
import If from '../If';
import {
  Button,
  Content,
  Description,
  Title,
  WrapperButtons,
} from './card.styles';

type Props = {
  title: string;
  description: string;
  onExecute: () => void;
  onEditPress?: () => void;
  editMode?: boolean;
  titleBtn?: string;
};

const CardProcess: FC<Props> = ({
  title,
  description,
  onExecute,
  editMode = false,
  onEditPress = () => {},
  titleBtn = 'Executar',
}) => (
  <Content>
    <Title>{parseProcessName(title)}</Title>
    <Description>{description.replaceAll('_', ' ')}</Description>

    <WrapperButtons>
      <Button onClick={onExecute} fullWidth={!editMode}>
        {titleBtn}
      </Button>

      <If condition={editMode}>
        <Button onClick={onEditPress}>Editar</Button>
      </If>
    </WrapperButtons>
  </Content>
);

export default CardProcess;
