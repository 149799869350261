export const STATUS_CREATION_USER = {
  uid_categoria: '',
  uid_subcategoria: '',
};

export const DEFAULT_USER_DATA: UserType.Data = {
  username: '',
  name: '',
  uid: '',
  group: [],
  logged: false,
  // permissions: [STATUS_CREATION_USER],
  // ts: '',
  level: '',
};

export const STATUS_EXECUTIONS = {
  SUCCESS: 'completed',
  ERROR: 'error',
  SCHEDULE: 'schedule',
};
