import { isEmpty } from 'lodash';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { Process } from '~/scenes';
import { FormsAPI } from '~/services/api';
import { toast, useStores } from '~/utils';

export default class FormStore {
  @observable
  currentForm: Partial<Formulation.CurrentForm> = {
    uid: '',
    fields: null,
  };

  constructor() {
    makeObservable(this);
  }

  @action
  createField = async ({
    identifier,
    placeholder,
    type,
    value,
  }: ProcessType.SubProcessFields) => {
    try {
      const exist = this.currentForm.fields?.some(
        (f) => f.identifier === identifier,
      );

      if (exist) {
        toast('Já existe um campo com esse nome', { type: 'error' });
        return;
      }

      const forms = await FormsAPI.newField({
        uid: this.currentForm.uid,
        new_field: {
          identifier,
          placeholder,
          type,
          value,
        },
      });

      if (isEmpty(forms)) return;

      runInAction(() => {
        this.currentForm = forms;
        sessionStorage.setItem(
          'selected_sub_process',
          JSON.stringify(this.currentForm),
        );
      });
    } catch (error) {
      // ..
    }
  };

  @action
  fetch = ({ uid, fields }: Partial<ProcessType.SubProcessType>) => {
    try {
      runInAction(() => {
        this.currentForm = {
          uid,
          fields,
        };
      });
    } catch (error) {
      // ..
    }
  };

  @action
  update = async () => {
    try {
      // ..
      toast('Atualizado com sucesso!', { type: 'success' });
    } catch (error) {
      toast('Operação não realizada', { type: 'error' });
    }
  };

  @action
  deleteField = async ({ identifier }: Formulation.Remove) => {
    try {
      runInAction(() => {
        this.currentForm.fields = this.currentForm.fields?.filter(
          (field) => field.identifier !== identifier,
        );
      });

      const forms = await FormsAPI.remove(this.currentForm.uid, identifier);

      if (isEmpty(forms)) return;
      runInAction(() => {
        sessionStorage.setItem(
          'selected_sub_process',
          JSON.stringify(this.currentForm),
        );
      });
    } catch (error) {
      toast('Falha na operação', { type: 'error' });
    }
  };
}
