import { Icon, MenuItem, Select } from '@material-ui/core';
import { getTheme, ifStyle, pxToRem } from '~/utils';
import { styled } from '~/utils/modules';

type FieldType = {
  error?: boolean;
};

// Query
const inMobile = getTheme('inMobile');

// Colors
const primaryMain = getTheme('primary.main');
const failure = getTheme('failure');

// Radius
const mediumRadius = getTheme('mediumRadius');

// Spacing
const mediumSpacing = getTheme('mediumSpacing');

// Ifs
const isError = ifStyle('error');
const isDisabled = ifStyle('disabled');

export const Content = styled.div`
  flex-direction: column;
  width: 30%;
  min-height: 50%;
  margin: 0 auto;
  align-self: center;
  border-radius: ${mediumRadius};
  padding: ${mediumSpacing};
  background-color: ${primaryMain};
  align-items: center;
  justify-content: space-between;

  @media ${inMobile} {
    width: 90%;
  }
`;

export const Field = styled.input<FieldType>`
  border: ${(props) => isError(`2px solid ${failure(props)}`, 0)(props)};
  border-radius: 10px;
  height: ${pxToRem(55)};
  background-color: rgba(0, 36, 76, 0.2);
  margin-top: 20px;
  padding: 10px;
  font-size: 1rem;
  color: #333;
  width: 100%;

  @media ${inMobile} {
    border-radius: 5px;
    height: ${pxToRem(40)};
    width: 100%;
  }
`;

export const LevelUserText = styled.h4`
  width: 100%;
  text-align: left;
  padding-top: ${mediumSpacing};
  margin: 0;
  font-weight: 500;
  color: #333;
`;

export const SelectStyled = styled(Select)`
  width: 100%;
  background-color: rgba(0, 36, 76, 0.2);

  @media ${inMobile} {
    border-radius: 5px;
    height: ${pxToRem(40)};
    width: 100%;
  }
`;

export const Option = styled(MenuItem)``;

export const Button = styled.button`
  cursor: pointer;
  border: 0;
  border-radius: 10px;
  height: ${pxToRem(50)};
  background-color: ${isDisabled('rgba(0, 36, 76, 0.5)', 'rgba(0, 36, 76, 1)')};
  margin-top: 20px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #fafafa;
  width: 100%;

  @media ${inMobile} {
    border-radius: 5px;
    width: 100%;
    align-items: center;
    height: ${pxToRem(43)};
  }
`;

export const TextError = styled.h4`
  width: 100%;
  text-align: center;
  padding-top: ${mediumSpacing};
  margin: 0;
  font-weight: 500;
  color: ${failure};
`;

export const IconStyled = styled(Icon)`
  width: 90%;
  align-self: flex-end;
  cursor: pointer;
`;
