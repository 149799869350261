import fileDownload from 'js-file-download';
import { Request } from '..';

export const run = async ({
  repository_name,
  custom_fields,
  processUid,
  quantity,
  subProcessUid,
  group,
}: ExecutionsType.ExecutionsParamsAPI): Promise<ExecutionsType.ExecutionsResponseAPI | null> => {
  try {
    const { data } = await Request.post('/executions', {
      repository_name,
      process_name: processUid,
      sub_process_name: subProcessUid,
      qtd_massa: quantity,
      custom_fields,
      group,
    });

    return data;
  } catch (error) {
    return null;
  }
};

export const schedule = async ({
  repository_name,
  custom_fields,
  processUid,
  quantity,
  subProcessUid,
  group,
}: ExecutionsType.ExecutionsParamsAPI): Promise<ExecutionsType.ExecutionsResponseAPI | null> => {
  try {
    const { data } = await Request.post('/executions/schedule', {
      repository_name,
      process_name: processUid,
      sub_process_name: subProcessUid,
      qtd_massa: quantity,
      custom_fields,
      group,
    });

    return data;
  } catch (error) {
    return null;
  }
};

export const listAll = async (): Promise<
  ExecutionsType.ExecutionsResponseAPI[]
> => {
  try {
    const { data } = await Request.get(`/executions`);
    return data;
  } catch (error) {
    return [];
  }
};

export const download = async (
  id: string,
  subcategoria: string,
): Promise<void> => {
  try {
    const { data } = await Request.get(`/executions/download/${id}`, {
      responseType: 'blob',
    });
    fileDownload(data, `${subcategoria}_${id}.json`);
  } catch (error) {
    // ..
  }
};
