import { Amplify } from 'aws-amplify';
import { fetchAuthSession, getCurrentUser, signOut } from 'aws-amplify/auth';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { CookieStorage } from 'aws-amplify/utils';
import {
  REACT_APP_COGNITO_POOL_CLIENT_ID,
  REACT_APP_COGNITO_POOL_ID,
  alert,
} from '~/utils';

class AmplifyAuth {
  constructor() {
    Amplify.configure({
      Auth: {
        Cognito: {
          userPoolId: REACT_APP_COGNITO_POOL_ID || '',
          userPoolClientId: REACT_APP_COGNITO_POOL_CLIENT_ID || '',
        },
      },
    });
    cognitoUserPoolsTokenProvider.setKeyValueStorage(
      new CookieStorage({ domain: 'primecontrol.com.br', secure: true }),
    );
  }

  // logged
  verifyUser = async (): Promise<boolean> => {
    try {
      const user = await getCurrentUser();
      return true;
    } catch (error) {
      return false;
    }
  };

  getUsername = async (): Promise<{ username: string }> => {
    const { username } = await getCurrentUser();

    return { username };
  };

  logout = async (): Promise<boolean> => {
    try {
      await signOut({ global: true });
      return true;
    } catch (error) {
      return false;
    }
  };

  getToken = async (): Promise<string> => {
    try {
      const session = await fetchAuthSession(); // Fetch the authentication session
      return session.tokens?.accessToken.toString() || '';
    } catch (e) {
      return '';
    }
  };
}

export default AmplifyAuth;
