import { Request } from '~/services';
import { ResponseError } from '~/utils';

export const fetch = async ({
  uid,
}: Formulation.Fetch): Promise<Formulation.CurrentForm> => {
  try {
    const { data } = await Request.get<Formulation.CurrentForm>(
      `/forms/${uid}`,
    );

    return data;
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const newField = async ({
  uid,
  new_field,
}: Formulation.NewFieldAPI): Promise<Formulation.CurrentForm> => {
  try {
    const { data } = await Request.put<Formulation.CurrentForm>(
      `/forms/${uid}`,
      { new_field },
    );

    return data;
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const remove = async (
  uid: string = '',
  identifier: string,
): Promise<Formulation.CurrentForm> => {
  try {
    const { data } = await Request.delete<Formulation.CurrentForm>(
      `/forms/delete-field/${uid}/${identifier}`,
    );

    return data;
  } catch (error) {
    throw new ResponseError(error);
  }
};
