import { Icon, MenuItem, Select } from '@material-ui/core';
import { getTheme, pxToRem } from '~/utils';
import { styled } from '~/utils/modules';

// Colors
const inMobile = getTheme('inMobile');
const failure = getTheme('failure');

// Spacing
const smallSpacing = getTheme('smallSpacing');

export const Container = styled.div`
  flex-direction: column;
`;

export const Field = styled.input`
  border: 1px solid #333;
  border-radius: ${pxToRem(2.5)};
  background-color: #e4dede;
  width: ${pxToRem(350)};
  height: ${pxToRem(55)};
  font-size: ${pxToRem(20)};
  padding: ${pxToRem(10)};
  margin-bottom: ${pxToRem(4)};

  @media ${inMobile} {
    width: 100%;
  }
`;

export const SelectStyled = styled(Select)`
  border: 1px solid #333;
  width: ${pxToRem(350)};
`;

export const Option = styled(MenuItem)``;

export const WrapperField = styled.div`
  flex-direction: row;
  align-items: center;
`;

export const IconRemove = styled(Icon)`
  cursor: pointer;
  color: ${failure};
  margin-left: ${smallSpacing};
`;

export const WrpField = styled.div`
  width: 48%;
  margin: 2px;
  display: flex;
  flex-direction: column;
`;

export const TitleField = styled.h5`
  margin: 0;
  font-weight: 500;
  color: #333;
`;
