import { Request } from '~/services';
import { ResponseError, parseProcessList } from '~/utils';

export const list = async (): Promise<ProcessType.Data[]> => {
  try {
    const { data } = await Request.get<ProcessType.ListProcessResponse[]>(
      `/process`,
    );
    return data.map(parseProcessList);
  } catch (error) {
    throw new ResponseError(error);
  }
};
