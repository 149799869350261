import service from '.';

class FormsService {
  static async get(isSubCategory: string) {
    try {
      const { data } = await service.get(`/forms/${isSubCategory}`);
    } catch (error) {
      // ..
    }
  }

  static async delete({ idForm, idSubCategory }: Formulation.Delete) {
    try {
      const { data } = await service.delete('/forms', {
        data: {
          idSubCategory,
          idForm,
        },
      });
    } catch (error) {
      // ..
    }
  }

  static create = async ({
    identifier,
    placeholder,
    type,
    value,
    sub_process,
  }: Formulation.CreateFormParams) => {
    try {
      // TODO: Verificar se o campo já existe

      const { data } = await service.post('/forms', {
        identifier,
        placeholder,
        type,
        value,
        sub_process,
      });
    } catch (error) {
      // ..
    }
  };
}

export default FormsService;
