import { getTheme, ifStyle, pxToRem } from '~/utils';
import { styled } from '~/utils/modules';

type Props = {
  fullWidth?: boolean;
};

// Query
const inMobile = getTheme('inMobile');

// Colors
const primaryMain = getTheme('primary.main');
const primaryDark = getTheme('primary.dark');
const secondaryDark = getTheme('secondary.dark');
const tertiaryDark = getTheme('tertiary.dark');

// Spacing
const smallSpacing = getTheme('smallSpacing');

// Radius
const mediumRadius = getTheme('mediumRadius');
const smallRadius = getTheme('smallRadius');

// Ifs
const isFullWidth = ifStyle('fullWidth');

export const Content = styled.div`
  z-index: 0;
  cursor: pointer;
  min-width: ${pxToRem(250)};
  min-height: ${pxToRem(150)};
  background-color: #fff;
  padding: ${smallSpacing};
  margin: ${smallSpacing};
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: ${mediumRadius};
  box-shadow: 0 0 10px 0 #eee;
  justify-content: space-between;

  @media ${inMobile} {
    width: 100%;
  }
`;

export const Title = styled.h3`
  margin: 0;
  font-size: ${pxToRem(30)};
  color: ${primaryDark};

  @media ${inMobile} {
    font-size: ${pxToRem(25)};
  }
`;

export const Description = styled.h4`
  margin: 0;
  color: ${primaryDark};
  font-weight: 500;
  font-size: ${pxToRem(13)};
  max-width: ${pxToRem(250)};
`;

export const Button = styled.button<Props>`
  cursor: pointer;
  border: 0;
  width: ${isFullWidth('100%', '49%')};
  background-color: ${secondaryDark};
  color: ${primaryMain};
  font-weight: 400;
  height: ${pxToRem(30)};
  font-size: ${pxToRem(15)};
  border-radius: ${smallRadius};

  :hover {
    background-color: ${tertiaryDark};
    transition: 0.5s;
  }
`;

export const WrapperButtons = styled.div`
  flex-direction: row;
  justify-content: space-between;
`;
