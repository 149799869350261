import service from '.';

class UserService {
  static create = async ({
    email,
    enabled,
    level,
    name,
  }: UserType.FirestoreRegisterUser) => {
    try {
      const { data } = await service.post('/users', {
        email,
        enabled,
        level,
        name,
      });
      return data;
    } catch (error) {
      // ..
    }
  };

  static update = async ({
    name,
    level,
    permissions,
    enabled,
    emailQuery,
  }: UserType.UpdateUserParams) => {
    try {
      const { data } = await service.put(`/users/${emailQuery}`, {
        name,
        level,
        permissions,
        enabled,
      });
      return data;
    } catch (error) {
      // ..
    }
  };

  static list = async () => {
    try {
      const { data } = await service.get('/users');
      return data;
    } catch (error) {
      // ..
    }
  };

  static remove = async ({ emailQuery }: UserType.DeleteUserParams) => {
    try {
      const { data } = await service.delete(`/users/${emailQuery}`);
      return data;
    } catch (error) {
      // ..
    }
  };
}

export default UserService;
