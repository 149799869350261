import * as React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

type Props = {
  onSuccess: () => void;
  onCancel: () => void;
  description: string;
  title: string;
  open: boolean;
};
const AlertDialog: React.FC<Props> = ({
  description,
  onCancel,
  onSuccess,
  title,
  open,
}) => (
  <Dialog
    open={open}
    onClose={onCancel}
    style={{
      paddingLeft: '30vw',
    }}
  >
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>

    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {description}
      </DialogContentText>
    </DialogContent>

    <DialogActions>
      <Button onClick={onCancel}>Cancelar</Button>
      <Button onClick={onSuccess} autoFocus>
        Continuar
      </Button>
    </DialogActions>
  </Dialog>
);

export default AlertDialog;
