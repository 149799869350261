import { action, makeObservable, observable, runInAction } from 'mobx';
import { persist } from 'mobx-persist';
import { UserAPI } from '~/services/api';
import { DEFAULT_USER_DATA, alert } from '~/utils';
import RootStore from './index';

export default class UserStore {
  @observable
  root: typeof RootStore;

  @persist('object')
  @observable
  accessToken: string = '';

  @persist('object')
  @observable
  data: UserType.Data = DEFAULT_USER_DATA;

  @persist('object')
  @observable
  users: UserType.DataFirestore[] | [] = [];

  @observable
  userEditing: Partial<UserType.DataFirestore> = {};

  constructor(root: typeof RootStore) {
    this.root = root;
    makeObservable(this);
  }

  @action
  setLogged = (logged: boolean): void => {
    runInAction(() => {
      this.data = { ...this.data, logged };
    });
  };

  @action
  setDataUserKeycloak = (data: Partial<UserType.Data>): void => {
    runInAction(() => {
      this.data = { ...this.data, ...data };
    });
  };

  // @action
  // login = async ({
  //   username,
  //   password,
  // }: UserType.LoginParams): Promise<void> => {
  //   try {
  //     const data = await UserAPI.oAuth({ username, password });

  //     if (data.access_token) {
  //       runInAction(() => {
  //         this.data = { ...this.data, logged: !!data.access_token, username };
  //         Storage.setToken(data.access_token);
  //       });

  //       this.fetchUser();
  //     }
  //   } catch (error) {
  //     alert({
  //       message: 'Usuário ou senha inválidos',
  //       position: 'top-right',
  //     });
  //   }
  // };

  @action
  setDataUserToEdit = (data: UserType.DataFirestore) => {
    runInAction(() => {
      this.userEditing = data;
    });
  };

  @action
  fetchUser = async () => {
    try {
      const user = await UserAPI.fetch({ username: this.data.username });

      if (!user) return;

      runInAction(() => {
        this.data = { ...this.data, ...user };
      });
    } catch (error) {
      // ..
    }
  };

  // To remove
  @action
  create = async (args: any) => {
    // ..
  };

  @action
  onStatusSession = (): void => {
    // ..
  };

  @action
  listAllUsers = async (): Promise<any> => {
    // ..
  };

  @action
  update = async (args: any) => {
    // ..
  };

  @action
  remove = async (args: any) => {
    // ..
  };

  @action
  setUserKeycloak = async (data: any) => {
    runInAction(() => {
      this.data = {
        ...this.data,
        name: data.name,
        username: data.preferred_username,
        uid: data.sub,
        logged: true,
        group: data.group,
      };
    });
  };
}
