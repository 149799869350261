import { Icon } from '@material-ui/core';
import { getTheme, pxToRem } from '~/utils';
import { styled } from '~/utils/modules';

// Query
const inMobile = getTheme('inMobile');

// Colors
const primaryMain = getTheme('primary.main');
const primaryDark = getTheme('primary.dark');

// Spacing
const smallSpacing = getTheme('smallSpacing');

export const Content = styled.div`
  flex: 1;
  width: 100%;
  background-color: ${primaryMain};
  flex-direction: column;
`;

export const Childrens = styled.div`
  flex-direction: column;
  padding: ${smallSpacing};
`;

export const SectionCards = styled.div`
  padding-top: ${smallSpacing};
  flex-wrap: wrap;
  flex-direction: row;
  border-top: 0.5px;
  border-top-style: solid;
  border-top-color: #ddd;
`;

export const SectionTitle = styled.div`
  flex-direction: row;
  align-items: center;
  margin-bottom: ${smallSpacing};
`;

export const Title = styled.h5`
  margin: 0;
  color: ${primaryDark};
  font-size: ${pxToRem(30)};
  margin-left: ${smallSpacing};

  @media ${inMobile} {
    font-size: ${pxToRem(20)};
  }
`;

export const IconStyled = styled(Icon)`
  color: ${primaryDark};
  cursor: pointer;
`;
